import { supabase } from "../libs/api";

const fetchSteps = async(connection_id, stepsFrontendData) => {
  let { data: stepData, error } = await supabase
    .from('connection_onboarding_steps')
    .select('*')
    .eq('connection_id', connection_id)
    .eq('is_visible', true);

  if (error) {
    console.error('Error fetching steps:', error);
    return;
  }

  const firstStep = stepData.find(s => s.step_id === 0);
  if (firstStep && firstStep.status === 'upcoming') {
    // console.log("FirstStep id: ", firstStep.id);
    const { error: updateStepError } = await supabase
      .from('connection_onboarding_steps')
      .update({ status: 'current' })
      .eq('id', firstStep.id);

    if (updateStepError) {
      console.error("Update Step Error: ", updateStepError);
    } else {
      stepData = stepData.map(step =>
        step.id === firstStep.id ? { ...step, status: 'current' } : step
      );
    }
  }

  const enrichedData = stepData.map(st => {
    const matchingStep = stepsFrontendData.find(s => s.step_id === st.step_id);
    return {
      ...st,
      ...(matchingStep || {}),
    };
  });
  return enrichedData
};

const fetchCOSI = async(connection_id, firstStep) => {
  const { data: cosiData, error } = await supabase
    .from('connection_onboarding_step_inputs')
    .select('*')
    .eq('connection_onboarding_step_id', firstStep.id);
    //TODO: .eq('is_valid', true);

  if (error) {
    console.error('Error fetching COSI:', error);
    return;
  }

  return cosiData;
};

const getProfile = async ()=>{
  const { data, error } = await supabase
  .rpc('get_enriched_user_v2')
  if (error) {
    console.error('Error fetching data:', error);
    return;
  }
  const profileData = data;
  profileData.fullName = [profileData.first_name, profileData.last_name].filter(Boolean).join(' ');
  return profileData;
}

export {
  fetchSteps,
  fetchCOSI,
  getProfile
};
